import React, { useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import GoogleTranslate from "../Base/GoogleTranslate"
import Logo from "../../assets/sai_logo.png"
import DropdownItem from "../Shared/DropdownItems"
import "./Header.css"
import {
  extractIndustryData,
  extractRegionData,
  extractPassport1Data,
  extractPassport2Data,
  extractSyndicateReport,
  extractUpcomingsData,
} from "../../services"

export default function Header(props) {
  extractIndustryData().map(e => {
    return {
      sectionName: e.name,
      sectionLink: `/report/${e.slug}`,
      sectionItems: e.industrySectorList.map(ele => {
        return {
          name: ele.sectorName,
          link: `/report/sect/${ele.slug}`,
        }
      }),
    }
  })

  const [searchResults, setSearchResult] = useState(undefined)
  const [searchTerm, setSearchTerm] = useState("")
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  extractRegionData().map(e => {
    return {
      sectionName: e.cat_name,
      sectionLink: `/report/reg/${e.slug}`,
      sectionItems: e.sectorList.map(ele => {
        return {
          name: ele.sector_name,
          link: `/report/reg/sect/${ele.slug}`,
        }
      }),
    }
  })

  const navItems = [
    {
      name: "Industry Reports",
      link: "/industry",
      isDropDown: true,
      dropdownItems: [
        {
          sectionItems: [
            { name: "Consumer Oriented Sector", link: "/report/consumer-oriented-sectors" },
            { name: "Technology Sector", link: "/report/technology-sectors" },
            { name: "Defense Aerospace And Automotives", link: "/report/defence-aerospace-automotives" },
            { name: "Life Science", link: "/report/life-sciences" },
            { name: "Chemical Materials And Energy", link: "/report/chemicals-materials-energy" },
            { name: "Banking, Financial Services And Insurance Sector", link: "/report/banking-financial-insurance" },
          ],
        }],
      columns: 1,
    },
    // {
    //   name: "Regions",
    //   link: "/regions",
    //   isDropDown: true,
    //   dropdownItems: regionData,
    //   columns: 12,
    // },
    {
      name: "Solutions",
      link: "/solutions",
      isDropDown: true,
      dropdownItems: [
        {
          sectionItems: [
            { name: "B2B and B2C Consulting", link: "/b2b-b2c-consulting" },
            { name: "Market Research Reports", link: "/market-research-reports" },
            { name: "Data Analytics", link: "/data-analytics" },
            { name: "IT Solution", link: "/it-solutions" },
            { name: "SEO and Translation Services", link: "/seo-and-translation-services" },
          ],
        },
        // {
        //   sectionItems: [
        //   ],
        // },
      ],
      columns: 1,
    },
    {
      name: "Resources",
      link: "/news-and-resources",
      isDropDown: false,
      dropdownItems: [],
    },
    {
      name: "About Us",
      link: "/about-us",
      isDropDown: false,
      dropdownItems: [],
    },
    {
      name: "AIM Box",
      link: "/aim-box",
      isDropDown: false,
      dropdownItems: [],
    },
    {
      name: "Contact Us",
      link: "/contact-us",
      isDropDown: false,
      dropdownItems: [],
    },
  ]

  const reports = [
    ...extractPassport1Data(),
    ...extractPassport2Data(),
    ...extractSyndicateReport(),
    extractUpcomingsData(),
  ]
  useEffect(() => {
    const foundTerm = reports.filter(
      ele =>
        ele.title &&
        searchTerm &&
        ele.title.toUpperCase().includes(searchTerm.toUpperCase())
    )
    setSearchResult(foundTerm)
  }, [searchTerm])

  function gotoReport(report) {
    setSearchResult(undefined)
    document.getElementById("searchReport").value = ""
    navigate(
      `/market-report-research/${report.slug.toLowerCase()}-${report.report_type
      }${report.report_sub_type}`
    )
  }

  function validateAndNavigate() {
    if (searchResults.length === 0) {
      setSearchResult(undefined)
      document.getElementById("searchReport").value = ""
      navigate(`/submit-query`)
    } else if (searchResults.length === 1) {
      document.getElementById("searchReport").value = ""
      const report = searchResults[0]
      setSearchResult(undefined)
      navigate(
        `/market-report-research/${report.slug.toLowerCase()}-${report.report_type
        }${report.report_sub_type}`
      )
    } else {
      document.getElementById("searchReport").value = ""
      setSearchResult(undefined)
      navigate(`/search-results/${searchTerm}`)
    }
  }

  return (
    <>
      <div
        className="row"
        style={{ backgroundColor: "#0f0f7a", fontSize: "12px", marginRight: '0px' }}
      >
        {/* <div className="col-md-3 col-sm-12 text-center text-light align-content-center">
          <GoogleTranslate />
        </div> */}
        <div className="col-md-6 col-sm-12 text-right text-light pt-2">
          <i className="fa fa-phone" aria-hidden="true"></i>
          +91-9836913164 (India),&nbsp;
          <i className="fa fa-phone" aria-hidden="true"></i> +1-917-730-4660
          (U.S.)
        </div>
        <div className="col-md-6 col-sm-12 text-light text-right pt-2" id="header-menu" style={{ paddingRight: "50px" }}>
          <ul className="list-inline">
            <li className="list-inline-item">
              <Link to={"/"} className="text-white">
                Home
              </Link>
            </li>
            <li className="list-inline-item border-left pl-1">
              <Link to={"/news-and-resources"} className="text-white">
                News
              </Link>
            </li>
            <li className="list-inline-item border-left pl-1">
              <Link to={"/press-release"} className="text-white">
                Press Release
              </Link>
            </li>
            <li className="list-inline-item border-left pl-1">
              <Link to={"/blog"} className="text-white">
                Blog
              </Link>
            </li>
            <li className="list-inline-item border-left pl-1">
              <Link to={"/submit-query"} className="text-white">
                Query
              </Link>
            </li>
            {/* <li className="list-inline-item border-left pl-1">
              <Link to={"/login"} className="text-white">
                Login
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light pb-4 pr-0">
        <div className="col-md-3">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            style={{marginRight: "0px"}}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a href="/">
            <img
              src={Logo}
              height="57px"
              className="navbar-brand"
              alt="sheer analytics and insights logo"
            />
          </a>
        </div>
        <div className="collapse navbar-collapse col-md-9 pt-4" id="navbarNav">
          <ul className="navbar-nav d-flex justify-content-between">
            {navItems.map(item => {
              if (item.isDropDown) {
                return <DropdownItem item={item} />
              } else {
                return (
                  <li className="nav-item">
                    <Link className="nav-link" to={item.link} style={{paddingTop: "0.5rem !important"}}>
                      {item.name}
                    </Link>
                  </li>
                )
              }
            })}
            <li className="nav-item ml-4">
              <div className="input-group">
                <input
                  id="searchReport"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={e => setSearchTerm(e.target.value)}
                  value={searchTerm}
                  onKeyDown={e => e.keyCode === 13 && validateAndNavigate()}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    onClick={validateAndNavigate}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </div>
                <ul
                  className="mt-5 list-unstyled"
                  style={{
                    position: "absolute",
                    zIndex: "100",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  {searchResults &&
                    [...searchResults].splice(0, 5).map(e => (
                      <li
                        className="bg-muted p-2 text-muted border searchResultsUl"
                        onClick={() => gotoReport(e)}
                        style={{ cursor: "pointer" }}
                      >
                        {e.title.substring(0, 50).concat("...")}
                      </li>
                    ))}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      {showMobileMenu && (
        <ul className="list-unstyled text-center">
          {navItems.map(e => (
            <li className="bg-primary border p-2">
              <Link to={e.link} className="text-decoration-none text-white">
                {e.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
