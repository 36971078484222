
const axios = require('axios');
const url = 'https://www.sheeranalyticsandinsights.com';
// const url = 'http://localhost:3000';
const headers = {
  'Content-Type': 'application/json'
}

export function post(data, callback) {
  axios.post(url + '/api/sendEmail', data, {
    headers: headers
  })
    .then(function (response) {
      console.log(response);
      callback(true);
    }).catch(function (response) {
      //handle error
      console.log(response);
      callback(false);
    });
}

export function updatePressView(data) {
  axios.post(url + '/api/updatePressView', data, {
    headers: headers
  })
    .then(function (response) {
      console.log(response);
    }).catch(function (response) {
      //handle error
      console.log(response);
    });
}

export function updateBlogView(data) {
  axios.post(url + '/api/updateBlogView', data, {
    headers: headers
  })
    .then(function (response) {
      console.log(response);
    }).catch(function (response) {
      //handle error
      console.log(response);
    });
}

export function getCaptcha(data, callback) {
  return new Promise((resolve, reject) => {
    axios.get(url + '/api/fetchCaptchaSecret', data, {
      headers: headers
    })
      .then(function (response) {
        console.log(response);
        resolve(response.data);
      }).catch(function (response) {
        //handle error
        console.log(response);
        reject(response);
      });
  });
}

export function saveTransaction(data) {
  axios.post(url + '/api/saveTransaction', data, {
    headers: headers
  })
    .then(function (response) {
      console.log(response);
    }).catch(function (response) {
      //handle error
      console.log(response);
    });
}