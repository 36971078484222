import React from 'react';
import { Link } from "gatsby"

import './Hotlinks.css'

export default function HotLinks() {
    return (
        <div class="row pl-4 pr-4">
            <h6 class="font-weight-bold text-white mt-2 mb-2"><ins>Hot Links</ins></h6>
            <ul class="list-unstyled text-white col-md-12 pl-0">
                <Link to={"/career"} className="text-white display-block">
                    Career
                </Link>
                <Link to={"/contact-us"} className="text-white display-block">
                    Contact Us
                </Link>
                <Link to={"/disclaimer"} className="text-white display-block">
                    Disclaimer
                </Link>
                <Link to={"/terms-of-use"} className="text-white display-block">
                    Terms of Use
                </Link>
                <Link to={"/privacy-policy"} className="text-white display-block">
                    Privacy Policy
                </Link>
                <Link to={"/press-release"} className="text-white display-block">
                    Press Release
                </Link>
            </ul>
        </div>
    )
}