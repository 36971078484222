import b2bAndB2CConsulting from "../assets/b2b-and-b2c-consulting.jpg"
import dataAnalytics from "../assets/data-analytics.png"
import itSolutions from "../assets/it-solutions.png"
import marketResearchReports from "../assets/market-research-reports.jpg"
import seoAndTransactionServices from "../assets/seo-and-translation-services.jpg"

export default function() {
  return [
    {
      itemSrc: b2bAndB2CConsulting,
      title: "B2B B2C Consulting",
      link: "/b2b-b2c-consulting",
      borderColor: "rgb(122, 45, 150)",
      hoverHeading: "B2B B2C Consulting",
      hoverText:
        "We are leading global consulting firm that offers a wide range of services in both B2B and B2C consulting. The firm is known for its in-depth research and analysis, customized solutions, and strong relationships with clients.",
    },
    {
      itemSrc: marketResearchReports,
      title: "Market Research Report",
      link: "/market-research-reports",
      borderColor: "rgb(0, 32, 96)",
      hoverHeading: "Market Research Report",
      hoverText:
        "We are market research firm that offers a range of market research reports, including syndicated, passport, and custom reports.",
    },
    {
      itemSrc: dataAnalytics,
      title: "Data Analytics",
      link: "/data-analytics",
      borderColor: "rgb(0, 112, 192)",
      hoverHeading: "Data Analytics",
      hoverText:
        "We offers a variety of data analytics services that help businesses gain insights from their data. These services help businesses make informed decisions, optimize their operations, and stay ahead of the competition.",
    },
    {
      itemSrc: itSolutions,
      title: "IT Solutions",
      link: "/it-solutions",
      borderColor: "rgb(0, 176, 80)",
      hoverHeading: "IT Solutions",
      hoverText:
        "We are global IT solutions provider that offers a wide range of services in IT solutions covering FTE in Javascript, Python, Node JS, and other technologies, website designing, and software designing and coding.",
    },
    {
      itemSrc: seoAndTransactionServices,
      title: "SEO And Translation Service",
      link: "/seo-and-translation-services",
      borderColor: "rgb(255, 192, 0)",
      hoverHeading: "SEO And Translation Service",
      hoverText:
        "We are global market research and consulting firm that offers a wide range of services, including SEO and translation services.",
    }
  ]
}
